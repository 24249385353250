import React from "react"
import styled from "styled-components";

export const MainTitle = ({ variant, children }) => {
  if (variant === "h1") {
    return <H1>{children}</H1>
  }

  if(variant === 'h2') {
    return <H2>{children}</H2>
  }

  return <span>{children}</span>
}

export default MainTitle

const H1 = styled.h1`
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    font-size: 64px;
    margin-bottom: 24px;
  },
`

const H2 = styled.h2`
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    font-size: 40px;
    margin-bottom: 18px;
  },
`


