import React from "react"
import styled from "styled-components"

const MainBackground = ({
  children,
  type = "violet",
  container,
  clip = "70%",
  sectionContainer = false,
}) => {
  return (
    <Box sectionContainer={sectionContainer} container={container}>
      <ImageWrap>
        <Decor clip={clip} type={type}></Decor>
      </ImageWrap>
      <Content>{children}</Content>
    </Box>
  )
}

export { MainBackground }

const Box = styled.div`
  padding-top: ${({ container, sectionContainer }) =>
    sectionContainer ? "40px" : container ? "70px" : "40px"};
  padding-bottom: ${({ container, sectionContainer }) =>
    sectionContainer ? "40px" : container ? "0" : "50"};
  position: relative;
  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    padding-top: ${({ container, sectionContainer }) =>
      sectionContainer ? "40px" : container ? "135px" : "185px"};
  }

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const Decor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${props =>
      props.type === "violet" ? "#473974" : "#005475"};
  }
`
const ImageWrap = styled.div`
  img {
    z-index: -1;
    width: 40% !important;
    left: auto !important;
    right: 0;
  }
`
const Content = styled.div`
  position: relative;
  z-index: 50;
`
