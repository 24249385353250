import * as React from "react"
import Seo from "../components/Seo"

import styled from "styled-components"

import Layout from "../components/Layout"
import { MainBackground } from "../components/MainBackground"
import { Container } from "../components/Container"
import MainTitle from "../components/CommonComponents/MainTitle"

const NotFoundPage = () => (
  <Layout>
    <MainBackground>
      <Seo title="404: Not found" />
      <Container>
        <BodyContainer>
          <MainTitle variant="h1">404: Not Found</MainTitle>
          <Text>You just hit a route that doesn&#39;t exist...</Text>
        </BodyContainer>
      </Container>
    </MainBackground>
  </Layout>
)

export default NotFoundPage

const BodyContainer = styled.div`
  text-align: center;
  height: 60vh;
`

const Text = styled.h1`
  ${({ theme }) => theme.paragraphs.p};
  color: white;
`
